import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import MobileNavigation from './MobileNavigation';
import MobileNavContext from '../../context/MobileNavContext';
import constants from '../../lib/constants';
import './MainHeader.scss';

const MainHeader = () => {
  const mobileNavContext = useContext(MobileNavContext);
  const active = classNames('mobile-menu', {
    open: mobileNavContext.mobileNavOpen,
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > constants.MOBILE_MENU_WIDTH_THRESHOLD) {
        mobileNavContext.toggleMobileNav(false);
        mobileNavContext.toggleMobileNavView(false);
      } else if (window.innerWidth < constants.MOBILE_MENU_WIDTH_THRESHOLD) {
        mobileNavContext.toggleMobileNavView(true);
      }
      console.log("HI " + window.innerWidth + ", " + mobileNavContext.showMobileNavMenu);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <>
      <header>
        <div className="header-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-3" style={{ paddingLeft: 0 }}>
                <h3 className="logo-text"><Link to="/">ProblemDB.org</Link></h3>
              </div>
              <div className="col-2 col-sm-4 col-md-6 col-lg-8 col-xl-9 navigationbar">
                {
                  mobileNavContext.showMobileNavMenu ? (
                    <button
                      aria-label="Toggle Mobile Menu Button"
                      aria-expanded={mobileNavContext.mobileNavOpen}
                      className={active}
                      onClick={() => {
                        mobileNavContext.toggleMobileNav(
                          !mobileNavContext.mobileNavOpen,
                        );
                      }}
                    >
                      <div className="bar-one" />
                      <div className="bar-two" />
                      <div className="bar-three" />
                    </button>
                  ) : (
                    <div>
                      <ul className="main-menu">
                        <li><Link className="menu-link" activeClassName="active" to="/">Home</Link></li>
                        <li><Link className="menu-link" activeClassName="active" to="/new">What's New</Link></li>
                        <li><Link className="menu-link" activeClassName="active" to="/topics">Topics</Link></li>
                        <li><Link className="menu-link" activeClassName="active" to="/mission">Mission</Link></li>
                        <li><Link className="menu-link" activeClassName="active" to="/support">Support</Link></li>
                      </ul>
                      <div className="hamburger">
                        <span className="bar"></span>
                        <span className="bar"></span>
                        <span className="bar"></span>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </header>
      {mobileNavContext.showMobileNavMenu ? (
        <MobileNavigation open={mobileNavContext.mobileNavOpen} />
      ) : null}
    </>
  )
}

export default MainHeader