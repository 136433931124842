import * as React from 'react';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import MobileNavContext from '../context/MobileNavContext';
import MainHeader from './Header/MainHeader';
import constants from '../lib/constants';

const Layout = ({ pageTitle, children }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [showMobileNavMenu, setShowMobileNavMenu] = useState(false);
  const layoutContainerPositioning = classNames('layout-container', {
    open: mobileNavOpen,
  });

  useEffect(() => {
    // This layer determines whether the entire Mobile Menu is visible
    // The next layer down (in MainHeader) resets the values of the Mobile Menu when it appears/disappears
    if (window.innerWidth > constants.MOBILE_MENU_WIDTH_THRESHOLD) {
      setShowMobileNavMenu(false);
    } else if (window.innerWidth < constants.MOBILE_MENU_WIDTH_THRESHOLD) {
      setShowMobileNavMenu(true);
    }
  }, []);

  return (
    <MobileNavContext.Provider
      value={{
        mobileNavOpen,
        showMobileNavMenu,
        toggleMobileNav: (navState) => setMobileNavOpen(navState),
        toggleMobileNavView: (showNavBool) => setShowMobileNavMenu(showNavBool),
      }}
    >
      <div className={layoutContainerPositioning}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle} | ProblemDB</title>
        </Helmet>
        <MainHeader />
        <main>{children}</main>
        <footer>
          <div className="footer-content">
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.com">Gatsby</a>
          </div>
        </footer>
      </div>
    </MobileNavContext.Provider>
  )
}

export default Layout
