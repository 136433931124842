import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import './MobileNavigation.scss';

const MobileNavigation = ({ open }) => {
  const displayMobileNavLinks = classNames('mobile-nav-wrapper', {
    open: open,
  });

  return (
    <nav arai-label="mobile navigation" className={displayMobileNavLinks}>
      <ul className="mobile-menu">
        <li><Link className="mobile-link" activeClassName="active" to="/">Home</Link></li>
        <li><Link className="mobile-link" activeClassName="active" to="/topics">Topics</Link></li>
        <li><Link className="mobile-link" activeClassName="active" to="/new">What's New</Link></li>
        <li><Link className="mobile-link" activeClassName="active" to="/mission">Mission</Link></li>
        <li><Link className="mobile-link" activeClassName="active" to="/support">Support</Link></li>
      </ul>
    </nav>
  );
};

export default MobileNavigation;